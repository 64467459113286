import noProfileImage from "@app/assets/images/no-profile-image.svg";
import { CustomLoader } from "@app/components/custom-loader";
import { LogoutModal } from "@app/components/modals/logout-modal";
import { NewClientModal } from "@app/components/modals/new-client-modal";
import { Notifications } from "@app/components/notifications";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";
import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import routes, { type Route } from "@app/routes";
import { Transition } from "@headlessui/react";
import { type ReactNode, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import type { ViewProperties } from "./properties";
import "./sidebar.css";
import { BsQuestionCircle } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";

export const SidebarView = memo((props: ViewProperties) => {
	const { language } = useSelector((state: RootState) => state.language);
	const rootState = useSelector((state: RootState) => state);

	const location = useLocation();

	const hideSidebar =
		location.pathname === paths.clients || location.pathname === paths.account;

	const menuItems = useMemo(() => {
		const baseFilter = (route: Route) => {
			if (route.showInSidebar) return true;

			if (props.mobile) {
				if (typeof route.showInSidebarMobile === "boolean")
					return route.showInSidebarMobile;

				if (typeof route.showInSidebarMobile === "function")
					return route.showInSidebarMobile(rootState);
			}

			return false;
		};

		let filterFunc = (route: Route) => baseFilter(route);

		// If you are on clients and account settings page, only show links to pages which don't require a client
		if (hideSidebar)
			filterFunc = (route: Route) => baseFilter(route) && !route.client;

		return routes
			.filter(filterFunc)
			.sort((a, b) =>
				!a.index || !b.index || a.index === b.index
					? 0
					: a.index > b.index
						? 1
						: -1,
			)
			.map((x: Route) => ({
				path: x.path,
				displayName: x.displayName?.(language) || "",
			}))
			.concat([
				{
					path: paths.logout,
					displayName: "Logout",
				},
			]);
	}, [location, props.mobile, hideSidebar]);

	const sidebarContainerClasses =
		"sidebar flex flex-col fixed top-0 left-0 w-full h-screen py-2.5 lg:relative lg:py-12 lg:h-auto lg:grow lg:shrink-0";

	const getLink = (text: string, path: string, index: number) => {
		const classesToUse = [
			"sidebar-link flex font-primary-medium py-4 text-left",
			"w-full",
		];

		const linkClasses = "sidebar-link-inner pl-4 py-2.5";

		if (path === location.pathname) classesToUse.push("active");

		const isLogout = path === paths.logout;

		return isLogout ? (
			<Button
				key={index}
				className={classesToUse.join(" ")}
				onClick={props.onLogout}
			>
				<div className={linkClasses}>{text}</div>
			</Button>
		) : (
			<Link to={path} key={index} className={classesToUse.join(" ")}>
				<div className={linkClasses}>{text}</div>
			</Link>
		);
	};

	const getMobileNotificationsSection = () => (
		<div className="flex flex-row justify-end items-center gap-x-3 sidebar-notifications">
			<Notifications className="notifications mr-9" />
			<a
				className="question-contact"
				href={links.contact}
				target="_blank"
				rel="noreferrer"
			>
				<BsQuestionCircle size="28px" />
			</a>
		</div>
	);

	const getMobileTopSection = () => {
		return (
			<div className="mobile-top-section flex flex-row justify-between mb-3">
				{getMobileNotificationsSection()}
				<Button className="close-button flex-grow-0" onClick={props.onClose}>
					<IoCloseSharp size="35px" />
				</Button>
			</div>
		);
	};

	const getMenuHeader = () => {
		let userEmail: ReactNode;
		let relationshipManager: ReactNode;

		if (props.mobile) {
			userEmail = (
				<h4 className="user-email my-auto font-primary-bold text-right">
					{props.userEmail}
				</h4>
			);

			relationshipManager = (
				<div className="sidebar-manager flex flex-col items-start gap-y-2">
					<h3 className="font-primary-bold subheader">
						Your Relationship Manager
					</h3>
					<div className="flex flex-row flex-wrap justify-start items-center gap-x-4 gap-y-4 font-primary-medium">
						<img
							src={props.manager.picture || noProfileImage}
							alt="manager-image"
						/>
						<div className="manager-text flex flex-col flex-wrap">
							<span className="break-words">{`${props.manager.firstName} ${props.manager.lastName}`}</span>
							<a href={`mailto:${props.manager.email}`}>
								{props.manager.email}
							</a>
							<span>{props.manager.contactNumber}</span>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="menu-header flex flex-col mb-2.5 lg:mb-2.5 gap-y-2.5 min-w-0">
				<div className="flex flex-col gap-y-2.5">
					<h2>Menu</h2>
					{!hideSidebar && relationshipManager}
				</div>
			</div>
		);
	};

	const getMenuItems = () => {
		return (
			<div className="menu-items">
				{menuItems.map((x, index) => getLink(x.displayName, x.path, index))}
			</div>
		);
	};

	const getSidebarContent = () => {
		return (
			<div className="scrollable-content overflow-auto">
				{getMenuHeader()}
				{getMenuItems()}
			</div>
		);
	};

	const getDesktopSidebar = () => {
		return (
			<div className={sidebarContainerClasses}>
				<div className="sticky-content sticky">{getSidebarContent()}</div>
			</div>
		);
	};

	const getMobileSidebar = () => {
		return (
			<Transition
				className={sidebarContainerClasses}
				enter="transition ease-in-out duration-300 transform"
				enterFrom="-translate-x-full"
				enterTo="translate-x-0"
				leave="transition ease-in-out duration-300 transform"
				leaveFrom="translate-x-0"
				leaveTo="-translate-x-full"
				show={props.open}
			>
				{getMobileTopSection()}
				{getSidebarContent()}
			</Transition>
		);
	};

	return (
		<>
			{props.mobile ? getMobileSidebar() : getDesktopSidebar()}
			{props.showLoadingModal && <CustomLoader page />}
			{props.showLogoutModal && (
				<LogoutModal open onClose={props.onCloseLogoutModal} />
			)}
			{props.showNewClientModal && (
				<NewClientModal
					open
					onClose={() => props.onToggleNewClientModal(false)}
				/>
			)}
		</>
	);
});
