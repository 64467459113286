import type { Profile, User } from "@app/entities";
import type { AppDispatch } from "@app/redux";
import {
	activateUserAction,
	confirmPasswordResetAction,
	getProfileAction,
	loginAction,
	logoutAction,
	registerAction,
	resendUserActivationAction,
	resetPasswordAction,
	setNewPageLoadAction,
	setPowerLoginAction,
	setThrottlingTimeAction,
	setTokenHasExpiredAction,
	updateUserPasswordAction,
	updateUserUsernameAction,
} from "@app/redux/auth/auth-actions";
import { type MappedReasons, abortRequests } from "@app/services";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useTimer } from "react-use-precision-timer";

export const useLogin = () => {
	const dispatch: AppDispatch = useDispatch();

	const login = (
		username: string,
		password: string,
		callback?: (user: User) => void,
	) => {
		dispatch(loginAction(username, password, callback));
	};

	return [login];
};

export const useLogout = () => {
	const dispatch: AppDispatch = useDispatch();

	const logout = (callback?: (errors?: string[]) => void) => {
		dispatch(logoutAction(callback));
	};

	return [logout];
};

export const useClearUserData = () => {
	const dispatch: AppDispatch = useDispatch();

	const clearUserData = () => {
		abortRequests();
		dispatch({ type: "RESET" });
	};

	return [clearUserData];
};

export const useRegister = () => {
	const dispatch: AppDispatch = useDispatch();

	const register = (
		email: string,
		password: string,
		ref?: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(registerAction(email, password, ref, callback));
	};

	return [register];
};

export const useActivateUser = () => {
	const dispatch: AppDispatch = useDispatch();

	const activateUser = (
		userId: string,
		token: string,
		callback?: (errors?: string[]) => void,
	) => {
		dispatch(activateUserAction(userId, token, callback));
	};

	return [activateUser];
};

export const useResendUserActivation = () => {
	const dispatch: AppDispatch = useDispatch();

	const resendUserActivation = (
		email: string,
		callback?: (errors?: string[]) => void,
	) => {
		dispatch(resendUserActivationAction(email, callback));
	};

	return [resendUserActivation];
};

export const useResetPassword = () => {
	const dispatch: AppDispatch = useDispatch();

	const resetPassword = (
		email: string,
		callback?: (errors?: string[]) => void,
	) => {
		dispatch(resetPasswordAction(email, callback));
	};

	return [resetPassword];
};

export const useConfirmPasswordReset = () => {
	const dispatch: AppDispatch = useDispatch();

	const confirmPasswordReset = (
		userId: string,
		token: string,
		newPassword: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(confirmPasswordResetAction(userId, token, newPassword, callback));
	};

	return [confirmPasswordReset];
};

export const useUpdateUserPassword = () => {
	const dispatch: AppDispatch = useDispatch();

	const updateUserPassword = (
		currentPassword: string,
		newPassword: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(updateUserPasswordAction(currentPassword, newPassword, callback));
	};

	return [updateUserPassword];
};

export const useUpdateUserUsername = () => {
	const dispatch: AppDispatch = useDispatch();

	const updateUserUsername = (
		currentPassword: string,
		newUsername: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(updateUserUsernameAction(currentPassword, newUsername, callback));
	};

	return [updateUserUsername];
};

export const useGetProfile = () => {
	const dispatch: AppDispatch = useDispatch();

	const getProfile = (callback?: (profile?: Profile) => void) => {
		var _resolve = (response?: Profile | PromiseLike<Profile>) => {},
			_reject = (reason?: any) => {};

		var promise = new Promise<Profile | undefined>((resolve, reject) => {
			_resolve = resolve;
			_reject = reject;
		});

		const _callback = (response?: Profile) => {
			callback && callback(response);
			_resolve(response);
		};

		try {
			dispatch(getProfileAction(_callback));
		} catch (error: any) {
			_reject(error);
		} finally {
			return promise;
		}
	};

	return [getProfile];
};

export const useSetThrottlingTime = () => {
	const dispatch: AppDispatch = useDispatch();

	const setThrottlingTime = (throttlingSeconds: number) => {
		dispatch(setThrottlingTimeAction(throttlingSeconds));
	};

	return [setThrottlingTime];
};

export const useSetTokenHasExpired = () => {
	const dispatch: AppDispatch = useDispatch();

	const setTokenHasExpired = (hasExpired: boolean) => {
		dispatch(setTokenHasExpiredAction(hasExpired));
	};

	return [setTokenHasExpired];
};

export const useThrottlingTimer = (
	throttlingTime: number | undefined,
	tickTime: number | undefined,
	onTick: () => void,
) => {
	const throttlingTimer = useTimer({
		delay: tickTime ?? 1000,
		callback: onTick,
	});

	const checkActivateTimer = () => {
		const throttlingTimeAsMoment = moment(throttlingTime);
		if (
			throttlingTime &&
			throttlingTimeAsMoment.isAfter() &&
			!throttlingTimer.isStarted()
		) {
			throttlingTimer.start();
		}
	};

	React.useEffect(() => {
		checkActivateTimer();

		return () => {
			throttlingTimer.isStarted() && throttlingTimer.stop();
		};
	}, []);

	React.useEffect(() => {
		checkActivateTimer();
		onTick();
	}, [throttlingTime]);
};

export const useSetPowerLogin = () => {
	const dispatch: AppDispatch = useDispatch();

	const setPowerLogin = (authToken: string) => {
		return dispatch(setPowerLoginAction(authToken));
	};

	return [setPowerLogin];
};

export const useSetNewPageLoad = () => {
	const dispatch: AppDispatch = useDispatch();

	const setNewPageLoad = (newPageLoad: boolean) => {
		return dispatch(setNewPageLoadAction(newPageLoad));
	};

	return [setNewPageLoad];
};
